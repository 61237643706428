import Flex from "Components/Flex.js";
import SubduedStateLabel from "Components/SubduedStateLabel.js";
import scss from "./LabelledCheckbox.module.scss";
import {memo, useCallback} from "react";
import {Form} from "semantic-ui-react";
import {v4 as uuid} from "uuid";

const LabelledCheckbox = memo(({
	disabled,
	name,
	onChange,
	value,
	label,
	caption,
	style
}) => {

	if (!name) {
		name = uuid();
	}

	const controlId = `checkbox_${name}`;

	const handleChange = useCallback((e, {name, value}) => {
		onChange(!!value, name);
	}, [onChange]);

	return (
		<Flex
			columnar={true}
			gap={0.5}
			style={style}>
			<Form.Checkbox
				checked={value}
				className={scss.control}
				disabled={disabled}
				id={controlId}
				name={name}
				onChange={handleChange}
				value={(value ? 0 : 1)} />
			<Flex gap={0}>
				<label className={scss.label} htmlFor={controlId}><strong>{label}</strong></label>
				{(caption && <SubduedStateLabel content={caption} />)}
			</Flex>
		</Flex>
	);

});

export default LabelledCheckbox;
